<template>
	<div>
		<div class="page-title">
			<a>
				<router-link to="/store/user/index">
					<el-button size="mini" icon="el-icon-back" class="mr-3">返回</el-button>
				</router-link>
			</a>
			<span style="font-size: 20px;">添加管理员</span>
		</div>
		<div class="bg-white p-3 m-2">
			<el-form ref="form" :model="form" :rules="rules" label-width="80px" style="max-width: 750px;">
				<el-form-item label="用户名" prop="user_name">
					<el-input v-model="form.user_name" placeholder="请输入用户名"></el-input>
				</el-form-item>
				<el-form-item label="角色" prop="role_id">
					<el-select v-model="form.role_id" placeholder="请选择角色">
						<el-option v-for="(item,index) in roleList" :key="index" :label="item.role_name"
							:value="item.role_id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="密码" prop="password">
					<el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
				</el-form-item>
				<el-form-item label="确认密码" prop="password_confirm">
					<el-input v-model="form.password_confirm" type="password" placeholder="请输入确认密码"></el-input>
				</el-form-item>
				<el-form-item label="姓名" prop="real_name">
					<el-input v-model="form.real_name" placeholder="请输入姓名"></el-input>
				</el-form-item>
				<el-form-item>
					<el-button type="primary" @click="submit('form')" :loading="loading">确 定</el-button>
				</el-form-item>
			</el-form>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['layout'],
		data() {
			return {
				loading: false,
				roleList: [],
				form: {
					user_name: '',
					password: '',
					role_id: '',
					password_confirm: '',
					real_name: '',
				},
				rules: {
					user_name: [{
						required: true,
						message: '请输入用户名',
						trigger: 'change'
					}, ],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'change'
					}, ],
					role_id: [{
						required: true,
						message: '请选择角色',
						trigger: 'change'
					}, ],
					password_confirm: [{
						required: true,
						message: '请输入确认密码',
						trigger: 'change'
					}, ],
					real_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'change'
					}, ],
				},
			}
		},
		created() {
			this.getRoleList()
		},
		methods: {
			getRoleList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/store.role/index',
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.roleList = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.loading = true;
						this.axios({
							token: true,
							params: {
								s: 'store/store.user/add',
							},
							data: {
								user: this.form
							},
							method: 'post',
						}).then(res => {
							this.loading = false;
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								// 跳转后台首页
								this.$router.push({
									name: 'store.user/index'
								})
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
						}).catch(err => {
							this.loading = false;
						})
					}
				})
			}
		}
	}
</script>

<style>
</style>
